import React, { useCallback, useEffect } from 'react'
import { Supply } from 'src/@core/supply/supply.model'
import {
    ButtonTrackClickType,
    CardTrackClickType,
    EventType,
    GetButtonEventProperties,
    GetPageViewEventProperties,
    track,
} from '@/tracks'
import Head from 'next/head'
import Footer from '@/_shared/Footer'
import SupplySubmit from '@/home/supplySubmit/SupplySubmit'
import styles from 'styles/Home.module.scss'
import supplies from 'src/@core/supply/supply.gateway'
import BestPlatform from '@/home/bestPlatform'
import { Filter } from 'src/@core/search/search.entity'
import { GetStaticPropsContext, GetStaticPropsResult } from 'next'
import SQMedia from '@/home/SQMedia'
import Image from 'next/image'
import artex from '../public/whoTrust/artex.png'
import guaco from '../public/whoTrust/guaco.png'
import kopenhagen from '../public/whoTrust/kopenhagen.png'
import nema from '../public/whoTrust/nema.png'
import semenzato from '../public/whoTrust/semenzato.png'
import theCoffee from '../public/whoTrust/the-coffee-sm.png'
import { captureException } from '@sentry/nextjs'
import SupplySection from '@/home/sections/SupplySection'
import HomeHeader from '@/home/HomeHeader'
import HomeNav from '@/home/HomeNav'
import PreFooter from '@/_shared/PreFooter'
import { Banner } from '@sq/components'
import { LightBulbIcon } from '@heroicons/react/24/outline'
import { AuthContext } from 'src/context/auth'
import { useRouter } from 'next/router'

type PageProps = {
    pageView: Supply[]
    mostRecent: Supply[]
    beforeRestaurant: Supply[]
}

export async function getStaticProps(
    context: GetStaticPropsContext
): Promise<GetStaticPropsResult<PageProps>> {
    const pageViewReq = supplies.search({
        sort: {
            name: 'Mais vistos',
            value: 'relevance-score',
            sort: 'desc',
        },
        limit: 10,
        offset: 0,
    })

    const mostRecentReq = supplies.search({
        sort: {
            name: 'Mais recentes',
            value: 'published_at',
            sort: 'desc',
        },
        limit: 10,
        offset: 0,
    })

    const beforeRestaurantFilter: Filter = {
        name: 'antes-era',
        value: 'restaurante',
        operator: 'in',
        type: 'string',
    }

    const beforeRestaurantReq = supplies.search({
        filters: [beforeRestaurantFilter],
        sort: {
            name: 'Mais recentes',
            value: 'published_at',
            sort: 'desc',
        },
        limit: 10,
        offset: 0,
    })

    const [pageView, mostRecent, beforeRestaurant] = await Promise.allSettled([
        pageViewReq,
        mostRecentReq,
        beforeRestaurantReq,
    ])

    return {
        props: {
            pageView: pageView.status === 'fulfilled' ? pageView.value.data : [],
            mostRecent: mostRecent.status === 'fulfilled' ? mostRecent.value.data : [],
            beforeRestaurant:
                beforeRestaurant.status === 'fulfilled' ? beforeRestaurant.value.data : [],
        },
    }
}

export default function Index({ pageView, mostRecent, beforeRestaurant }: PageProps) {
    const title = 'Aluguel de Ponto Comercial em São Paulo - imóveis comerciais | SuaQuadra'
    const description =
        'Lojas, salões e pontos comerciais para alugar em São Paulo. Encontre o imóvel comercial que mais combina com o seu negócio.'
    const { isLoggedIn } = React.useContext(AuthContext)
    const router = useRouter()

    const onRecommendedBannerClick = useCallback(async () => {
        track(
            EventType.ButtonClick,
            GetButtonEventProperties(
                'Clicou no CTA recomendados na home',
                ButtonTrackClickType.BannerRecommendedHome
            )
        )

        if (isLoggedIn) {
            router.push('/para-voce')
        } else {
            const currentUrl: URL = new URL('/login?redirectUrl=/para-voce', window.location.href)
            router.push(currentUrl)
        }
    }, [isLoggedIn, router])

    useEffect(() => {
        track(EventType.PageView, GetPageViewEventProperties())
    }, [])

    useEffect(() => {
        if (!pageView || pageView.length === 0) {
            captureException('Empty pageviews on home')
        }

        if (!mostRecent || mostRecent.length === 0) {
            captureException('Empty mostRecent on home')
        }

        if (!beforeRestaurant || beforeRestaurant.length === 0) {
            captureException('Empty beforeRestaurant on home')
        }
    }, [pageView, mostRecent, beforeRestaurant])

    return (
        <>
            <Head>
                <meta name="viewport" content="width=device-width, initial-scale=1" />

                <meta property="og:title" content={title} />
                <meta property="og:image" content="/arts/og-thumb.png" />
                <meta property="og:image:width" content="1200" />
                <meta property="og:image:height" content="630" />
                <meta property="og:description" content={description} />
                <meta name="description" content={description} />
                <title>{title}</title>
                <link rel="canonical" href={`https://suaquadra.com.br/`} />
            </Head>
            <div className={styles.Home}>
                <HomeNav />
                <HomeHeader />
                <section className="container mx-auto mb-10 flex justify-center px-4">
                    <Banner
                        icon={<LightBulbIcon className="size-6" />}
                        description="Imóveis recomendados para você"
                        buttonText="Ver recomendações"
                        onClick={onRecommendedBannerClick}
                    />
                </section>
                <div className="container mx-auto max-w-screen-xl pl-4">
                    {pageView?.length > 0 && (
                        <SupplySection
                            titulo={'Os mais populares'}
                            subtitulo={'Pontos comerciais mais visitados'}
                            destaque={'  '}
                            supplies={pageView}
                            trackOrigin={CardTrackClickType.PropertyCardSectionPageview}
                        />
                    )}
                    {mostRecent?.length > 0 && (
                        <SupplySection
                            titulo={'Acabou de chegar'}
                            subtitulo={'Imóveis comerciais publicados recentemente'}
                            destaque={'  '}
                            supplies={mostRecent}
                            trackOrigin={CardTrackClickType.PropertyCardSectionNew}
                        />
                    )}
                    {beforeRestaurant?.length > 0 && (
                        <SupplySection
                            titulo={'Perfeitos para restaurante'}
                            subtitulo={'Pontos comerciais ideais para o segmento'}
                            destaque={'  '}
                            supplies={beforeRestaurant}
                            showLinkToAll
                            trackOrigin={CardTrackClickType.PropertyCardSectionBeforeRestaurant}
                        />
                    )}
                </div>
                <div>
                    <div className={styles.whoTrust}>
                        <Image
                            className={`${styles.image} p-3`}
                            alt="Logo da empresa artex"
                            src={artex}
                            priority
                        />
                        <Image
                            className={styles.image}
                            alt="Logo da empresa guaco"
                            src={guaco}
                            priority
                        />
                        <Image
                            className={styles.image}
                            alt="Logo da empresa Kopenhagen"
                            src={kopenhagen}
                            priority
                        />
                        <Image
                            className={styles.image}
                            alt="Logo da empresa nema"
                            src={nema}
                            priority
                        />
                        <Image
                            className={styles.image}
                            alt="Logo da empresa semenzato"
                            src={semenzato}
                            priority
                        />
                        <Image
                            className={styles.image}
                            alt="Logo da empresa the coffee"
                            src={theCoffee}
                            priority
                        />
                    </div>
                    <BestPlatform />
                </div>
                <SupplySubmit />
                <SQMedia />
            </div>
            <PreFooter />
            <Footer />
        </>
    )
}
