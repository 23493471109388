import { useContext, useEffect } from 'react'
import { AddressesContext } from 'src/context/addresses'

const useAddresses = () => {
    const { regions, logradouros, fetchAddresses } = useContext(AddressesContext)

    useEffect(() => {
        fetchAddresses()
    }, [fetchAddresses])

    return { regions, logradouros }
}

export default useAddresses
