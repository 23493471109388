import styles from './SupplySubmit.module.scss'
import Image from 'next/image'
import pp from '/public/supplySubmit/pp.webp'
import SQButton from '@/_shared/SQButton/SQButton'
import { ArrowRightIcon } from '@heroicons/react/20/solid'
import { ButtonTrackClickType, EventType, GetButtonEventProperties, track } from '@/tracks'
import SQLink from '@/_shared/SQLink/SQLink'

export default function SupplySubmit() {
    const handleSupplySubmit = () => {
        track(
            EventType.ButtonClick,
            GetButtonEventProperties(
                'Divulgue seu imóvel - Supply Submit',
                ButtonTrackClickType.AdvertiseProperty
            )
        )
    }

    return (
        <div className={styles.SupplySubmit}>
            <div className={styles.contentWrapper}>
                <div className={styles.content}>
                    <div className={styles.contentTitle}>
                        <h3 className={styles.title}>
                            <span className="font-bold">É proprietário?</span>
                            <br />
                            Anuncie seu imóvel comercial e alugue mais rápido
                        </h3>
                    </div>

                    <p className={styles.contentText}>
                        Cadastre seu imóvel, ganhe fotos e plantas profissionais, receba visitas e
                        propostas e só pague na hora em que ele for alugado.
                    </p>

                    <SQButton className={styles.buttonContainer} onClick={handleSupplySubmit}>
                        <SQLink
                            withoutStyles
                            className={styles.button}
                            href="/proprietario/anunciar-imovel"
                        >
                            Anuncie seu imóvel comercial
                            <ArrowRightIcon style={{ height: '20px' }} />
                        </SQLink>
                    </SQButton>
                </div>
                <div className={styles.imageContainer}>
                    <Image className={styles.image} src={pp} alt="Casa comercial branca" fill />
                </div>
            </div>
        </div>
    )
}
