import React, { useState } from 'react'
import styles from './Nav.module.scss'
import Logo from '@/_shared/Logo'
import { SideMenu } from '@/_shared/SideMenu'
import AuthIcon from '@/auth/AuthIcon'
import SQLink from '@/_shared/SQLink/SQLink'
import { ButtonTrackClickType, EventType, GetButtonEventProperties, track } from '@/tracks'
import { Bars3Icon } from '@heroicons/react/24/solid'

export const trackAnnounceProperty = () =>
    track(
        EventType.ButtonClick,
        GetButtonEventProperties('Divulgue seu imóvel', ButtonTrackClickType.AdvertiseProperty)
    )

export const trackBlog = () =>
    track(EventType.ButtonClick, GetButtonEventProperties('Nav - Blog', ButtonTrackClickType.Blog))

export const trackHelp = () =>
    track(EventType.ButtonClick, GetButtonEventProperties('Nav - Ajuda', ButtonTrackClickType.FAQ))

function Nav() {
    const [isOpenMenu, setIsOpenMenu] = useState(false)

    return (
        <div className={styles.NavContainer}>
            <nav className={styles.nav}>
                <div className={styles.logo}>
                    <Logo />
                </div>
                <div className={styles.menu}>
                    <SQLink
                        className={`flex items-center font-normal`}
                        onClick={() => trackAnnounceProperty()}
                        href="/proprietario/anunciar-imovel"
                    >
                        Proprietários
                    </SQLink>
                    <SQLink
                        className={`flex items-center font-normal`}
                        href="https://blog.suaquadra.com.br/"
                        target="_blank"
                        onClick={() => trackBlog()}
                    >
                        Blog
                    </SQLink>
                    <SQLink
                        className={`flex items-center font-normal`}
                        href="/ajuda"
                        onClick={() => trackHelp()}
                    >
                        Ajuda
                    </SQLink>
                </div>
                <div className={styles.auth}>
                    <div className={styles.sideMenu}>
                        <button className="px-4" onClick={() => setIsOpenMenu((v) => !v)}>
                            <Bars3Icon className="size-6" />
                        </button>
                        <SideMenu isOpen={isOpenMenu} onClose={() => setIsOpenMenu(false)} />
                    </div>
                    <div className={styles.authIcon}>
                        <AuthIcon />
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default Nav
