import { ArrowLeftIcon } from '@heroicons/react/24/solid'
import ModalContainer from './ModalContainer'
import SearchTextInput from './SearchTextInput'
import {
    createLogradouroSearch,
    createQuerySearch,
    createRegionSearch,
    toSearchText,
} from 'src/@core/search/search.utils'
import SearchAutocomplete from './SearchAutocomplete'
import useSearchAutocomplete from '@/search/useSearchAutocomplete'
import { BairroLogradouroType, RegionType, SearchValue } from 'src/@core/search/search.model'
import { useMemo } from 'react'

type Props = {
    isVisible: boolean
    value: SearchValue
    regions: RegionType[]
    logradouros: BairroLogradouroType[]
    onChange: (value: SearchValue) => void
    onClose: () => void
}

export default function SearchInputModal({
    value,
    isVisible,
    regions,
    logradouros,
    onChange,
    onClose,
}: Props) {
    const queryText = useMemo(() => toSearchText(value), [value])

    const { filteredRegions, filteredLogradouros } = useSearchAutocomplete({
        query: queryText,
        regions,
        logradouros,
    })

    return (
        <ModalContainer isVisible={isVisible} className="bg-white">
            <div className="mb-auto overflow-y-scroll p-4">
                <button onClick={onClose} className="mb-5">
                    <ArrowLeftIcon className="size-6" />
                </button>
                <SearchTextInput
                    autoFocus={true}
                    value={queryText}
                    onChange={(e) => onChange(createQuerySearch(e.target.value))}
                    onCancel={() => onChange(createQuerySearch(''))}
                    className="mb-4"
                />
                <SearchAutocomplete
                    query={queryText}
                    filteredLogradouros={filteredLogradouros}
                    filteredRegions={filteredRegions}
                    fallBackLogradouros={logradouros.slice(0, 3)}
                    fallBackRegions={regions.slice(0, 3)}
                    onSelectRegion={(region) => onChange(createRegionSearch(region))}
                    onSelectLogradouro={(logradouro) =>
                        onChange(createLogradouroSearch(logradouro))
                    }
                />
            </div>
        </ModalContainer>
    )
}
