import React from 'react'
import styles from '@/_shared/Footer.module.scss'
import FacebookIcon from 'public/icons/facebook.svg'
import InstagramIcon from 'public/icons/insta.svg'
import LinkedInIcon from 'public/icons/linkedin.svg'
import SQLink from '@/_shared/SQLink/SQLink'
import Image from 'next/image'
import Logo from '@/_shared/Logo'
import {
    trackBlogClick,
    trackFAQClick,
    trackFooterAdvertisePropertyClick,
    trackFooterBrokerClick,
    trackFooterNeighborhoodClick,
    trackFooterTermsClick,
    trackPrivacyPolicyClick,
    trackSocialMediaClick,
} from '@/tracks/functions'

const year = new Date().getFullYear()

function Footer() {
    return (
        <div className={styles.Footer}>
            <div className={styles.links}>
                <div className={styles.content}>
                    <div className={styles.logo}>
                        <Logo />
                    </div>
                    <p className={styles.copyright}>
                        © {year} SuaQuadra Negócios e Tecnologia Ltda <br /> CNPJ:
                        46.021.111/0001-85{' '}
                    </p>

                    <SQLink
                        href="https://blog.suaquadra.com.br/"
                        target="_blank"
                        onClick={trackBlogClick}
                    >
                        Blog
                    </SQLink>
                    <SQLink href="/politica-de-privacidade" onClick={trackPrivacyPolicyClick}>
                        Privacidade
                    </SQLink>
                    <SQLink href="/termos" onClick={trackFooterTermsClick}>
                        Termos de uso
                    </SQLink>
                    <SQLink href="/ajuda" onClick={trackFAQClick}>
                        Central de ajuda
                    </SQLink>

                    <div className={styles.social}>
                        <SQLink
                            withoutStyles
                            href="https://www.linkedin.com/company/suaquadra/"
                            target="_blank"
                            onClick={() => trackSocialMediaClick('LinkedIn')}
                        >
                            <Image
                                src={LinkedInIcon}
                                width={24}
                                height={24}
                                alt={'LinkedIn logo'}
                            />
                        </SQLink>
                        <SQLink
                            withoutStyles
                            href="https://www.instagram.com/sua_quadra/"
                            target="_blank"
                            onClick={() => trackSocialMediaClick('Instagram')}
                        >
                            <Image
                                src={InstagramIcon}
                                width={24}
                                height={24}
                                alt={'Instagram logo'}
                            />
                        </SQLink>
                        <SQLink
                            withoutStyles
                            href="https://www.facebook.com/profile.php?id=100086215500716"
                            target="_blank"
                            onClick={() => trackSocialMediaClick('Facebook')}
                        >
                            <Image
                                src={FacebookIcon}
                                width={24}
                                height={24}
                                alt={'Facebook logo'}
                            />
                        </SQLink>
                    </div>
                </div>

                <div className={styles.content}>
                    <h2 className={styles.title}>Anúncio de imóveis comerciais</h2>
                    <SQLink
                        href="/proprietario/anunciar-imovel"
                        target="_blank"
                        onClick={trackFooterAdvertisePropertyClick}
                    >
                        Para proprietários
                    </SQLink>
                    <SQLink
                        href="https://marketing.suaquadra.com.br/parceiro-sq"
                        onClick={trackFooterBrokerClick}
                    >
                        Para corretores
                    </SQLink>
                </div>

                <div className={`${styles.content} align-self-end`}>
                    <h2 className={styles.title}>Aluguel de pontos comerciais</h2>
                    <SQLink
                        href="/imoveis/aluguel/campo-belo"
                        onClick={() => trackFooterNeighborhoodClick('Campo Belo')}
                    >
                        Campo Belo
                    </SQLink>

                    <SQLink
                        href="/imoveis/aluguel/higienopolis"
                        onClick={() => trackFooterNeighborhoodClick('Higienópolis')}
                    >
                        Higienópolis
                    </SQLink>
                    <SQLink
                        href="/imoveis/aluguel/itaim-bibi"
                        onClick={() => trackFooterNeighborhoodClick('Itaim Bibi')}
                    >
                        Itaim Bibi
                    </SQLink>
                    <SQLink
                        href="/imoveis/aluguel/lapa"
                        onClick={() => trackFooterNeighborhoodClick('Lapa')}
                    >
                        Lapa
                    </SQLink>
                    <SQLink
                        href="/imoveis/aluguel/perdizes"
                        onClick={() => trackFooterNeighborhoodClick('Perdizes')}
                    >
                        Perdizes
                    </SQLink>
                    <SQLink
                        href="/imoveis/aluguel/pinheiros"
                        onClick={() => trackFooterNeighborhoodClick('Pinheiros')}
                    >
                        Pinheiros
                    </SQLink>
                </div>
            </div>
        </div>
    )
}

export default Footer
