import { HighlightRanges } from '@nozbe/microfuzz'
import { useFuzzySearchList } from '@nozbe/microfuzz/react'
import { BairroLogradouroType, RegionType } from 'src/@core/search/search.model'

type Options = {
    query: string
    regions: RegionType[]
    logradouros: BairroLogradouroType[]
}

export type AutoCompleteResult<T> = {
    item: T
    highlightRanges: HighlightRanges | null
}

const useSearchAutocomplete = ({ query, regions, logradouros }: Options) => {
    const cutCount = query.length > 0 ? 7 : 3

    const filteredRegions = useFuzzySearchList<RegionType, AutoCompleteResult<RegionType>>({
        list: regions,
        queryText: query,
        getText: (result) => [result.bairro],
        mapResultItem: ({ item, matches: [highlightRanges] }) => ({
            item,
            highlightRanges,
        }),
    }).slice(0, cutCount)

    const filteredLogradouros = useFuzzySearchList<
        BairroLogradouroType,
        AutoCompleteResult<BairroLogradouroType>
    >({
        list: logradouros,
        queryText: query,
        getText: (result) => [[result.logradouro, result.bairro].join(', ')],
        mapResultItem: ({ item, matches: [highlightRanges] }) => ({
            item,
            highlightRanges,
        }),
    }).slice(0, cutCount)

    return { filteredRegions, filteredLogradouros }
}

export default useSearchAutocomplete
