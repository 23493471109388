import {
    BairroLogradouroType,
    Range,
    RegionType,
    SearchValue,
    SupplySearchParams,
} from 'src/@core/search/search.model'
import SubmitButton from './SubmitButton'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { ComponentProps, useCallback, useMemo, useState } from 'react'
import {
    createQuerySearch,
    priceToText,
    sizeToText,
    toSearchText,
} from 'src/@core/search/search.utils'
import SearchTextInput from './SearchTextInput'
import Accordion from './Accordion'
import PriceFilter from './PriceFilter'
import SizeFilter from './SizeFilter'
import ModalContainer from './ModalContainer'
import SearchInputModal from './SearchInputModal'
import { twMerge } from 'tailwind-merge'
import {
    clearSearch,
    setMaxPrice,
    setMaxSize,
    setMinPrice,
    setMinSize,
    setQuery,
    SupplySearchAction,
} from 'src/@core/search/search.state'

type Props = {
    isVisible: boolean
    isSubmitting: boolean
    regions: RegionType[]
    logradouros: BairroLogradouroType[]

    params: SupplySearchParams
    defaultPrice: Range
    defaultSize: Range
    onChange: (action: SupplySearchAction) => void

    onSubmit: () => void
    onClose: () => void
}

type AccordionName = 'address' | 'price' | 'size'

function ModalFooter({ children }: ComponentProps<'div'>) {
    return (
        <div
            className={twMerge(
                'absolute bottom-0 left-0 flex min-h-[115px] w-full flex-row items-center border-t border-sq-gray-200 bg-white px-8',
                'pb-[calc(env(safe-area-inset-bottom)+1rem)] pt-4'
            )}
        >
            {children}
        </div>
    )
}

export default function SearchParamsModal({
    isVisible,
    isSubmitting,
    params,
    defaultPrice,
    defaultSize,
    regions,
    logradouros,
    onChange,
    onSubmit,
    onClose,
}: Props) {
    const [openAccordion, setOpenAccordion] = useState<AccordionName | null>('address')
    const [isOpenQueryModal, setIsOpenQueryModal] = useState(false)
    const isAddressOpen = openAccordion === 'address'
    const isPriceOpen = openAccordion === 'price'
    const isSizeOpen = openAccordion === 'size'
    const queryText = useMemo(() => toSearchText(params.query), [params.query])
    const priceText = useMemo(
        () => (params.price ? priceToText(params.price) : undefined),
        [params.price]
    )
    const sizeText = useMemo(
        () => (params.size ? sizeToText(params.size) : undefined),
        [params.size]
    )

    const onToggleAccordion = (name: AccordionName) =>
        setOpenAccordion((cur) => (cur === name ? null : name))

    const onChangeQuery = (query: SearchValue) => {
        onChange(setQuery(query))
        setOpenAccordion('price')

        if (query.type !== 'query') {
            setIsOpenQueryModal(false)
        }
    }

    const onChangeMinPrice = (value: number) => onChange(setMinPrice(value))
    const onChangeMaxPrice = (value: number) => onChange(setMaxPrice(value))
    const onChangeMinSize = (value: number) => onChange(setMinSize(value))
    const onChangeMaxSize = (value: number) => onChange(setMaxSize(value))

    const onClear = () => {
        setOpenAccordion(null)
        onChange(clearSearch())
    }

    const onCloseQueryModal = useCallback(() => {
        setIsOpenQueryModal(false)

        if (params.query.type === 'query') {
            onChangeQuery(createQuerySearch(''))
        }
    }, [params.query, onChangeQuery])

    return (
        <>
            <ModalContainer isVisible={isVisible} className="bg-sq-gray-50">
                <div className="flex size-full flex-1 flex-col gap-4 overflow-y-auto p-4 pb-[120px]">
                    <button onClick={onClose} className="mb-5">
                        <XMarkIcon className="size-6" />
                    </button>

                    <Accordion id="address" isOpen={isAddressOpen} onToggle={onToggleAccordion}>
                        <Accordion.HeaderClosed>
                            <span className="font-bold">Onde?</span>
                            <span className="text-sm text-sq-gray-800">
                                {queryText || 'Qualquer lugar em São Paulo, SP'}
                            </span>
                        </Accordion.HeaderClosed>
                        <Accordion.HeaderOpen>
                            <span className="font-bold">Onde você procura?</span>
                        </Accordion.HeaderOpen>
                        <Accordion.Body>
                            <SearchTextInput
                                value={queryText}
                                readOnly={true}
                                placeholder="Qualquer lugar em São Paulo, SP"
                                onFocus={() => setIsOpenQueryModal(true)}
                            />
                        </Accordion.Body>
                    </Accordion>

                    <Accordion id="price" isOpen={isPriceOpen} onToggle={onToggleAccordion}>
                        <Accordion.HeaderClosed>
                            <span className="font-bold">Preço</span>
                            <span className="text-sm text-sq-gray-800">
                                {priceText ?? 'Defina o valor'}
                            </span>
                        </Accordion.HeaderClosed>
                        <Accordion.HeaderOpen>
                            <span className="font-bold">Qual faixa de preço?</span>
                        </Accordion.HeaderOpen>
                        <Accordion.Body>
                            <PriceFilter
                                value={params.price ?? defaultPrice}
                                onChangeMin={onChangeMinPrice}
                                onChangeMax={onChangeMaxPrice}
                            />
                        </Accordion.Body>
                    </Accordion>

                    <Accordion id="size" isOpen={isSizeOpen} onToggle={onToggleAccordion}>
                        <Accordion.HeaderClosed>
                            <span className="font-bold">Tamanho</span>
                            <span className="text-sm text-sq-gray-800">
                                {sizeText ?? 'Defina a metragem'}
                            </span>
                        </Accordion.HeaderClosed>
                        <Accordion.HeaderOpen>
                            <span className="font-bold">Qual tamanho?</span>
                        </Accordion.HeaderOpen>
                        <Accordion.Body>
                            <SizeFilter
                                value={params.size ?? defaultSize}
                                onChangeMin={onChangeMinSize}
                                onChangeMax={onChangeMaxSize}
                            />
                        </Accordion.Body>
                    </Accordion>

                    <ModalFooter>
                        <button
                            className="flex-1 text-left text-sm font-bold text-sq-gray-900 underline underline-offset-2"
                            onClick={onClear}
                        >
                            Limpar tudo
                        </button>
                        <SubmitButton
                            onClick={onSubmit}
                            className="ml-auto max-w-[158px]"
                            isLoading={isSubmitting}
                        />
                    </ModalFooter>
                </div>
            </ModalContainer>
            <SearchInputModal
                isVisible={isOpenQueryModal}
                value={params.query}
                regions={regions}
                logradouros={logradouros}
                onChange={onChangeQuery}
                onClose={onCloseQueryModal}
            />
        </>
    )
}
