import Link from 'next/link'
import { useState } from 'react'

const selectedTabClassName = 'border-b-2 border-b-black font-bold'

const TABS = ['Bairros', 'Ruas', 'Preço', 'Tamanho']

const neighborhoods = [
    { label: 'Pinheiros', link: '/imoveis/aluguel/pinheiros' },
    { label: 'Vila Mariana', link: '/imoveis/aluguel/vila-mariana' },
    { label: 'Santo Amaro', link: '/imoveis/aluguel/santo-amaro' },
    { label: 'Tatuapé', link: '/imoveis/aluguel/tatuape' },
    { label: 'Mooca', link: '/imoveis/aluguel/mooca' },
    { label: 'Itaim Bibi', link: '/imoveis/aluguel/itaim-bibi' },
    { label: 'Santana', link: '/imoveis/aluguel/santana' },
    { label: 'Ipiranga', link: '/imoveis/aluguel/ipiranga' },
    { label: 'Jardim Paulista', link: '/imoveis/aluguel/jardim-paulista' },
    { label: 'Barra Funda', link: '/imoveis/aluguel/barra-funda' },
    { label: 'Vila Madalena', link: '/imoveis/aluguel/vila-madalena' },
    { label: 'Perdizes', link: '/imoveis/aluguel/perdizes' },
]
const streets = [
    { label: 'Avenida Paulista', link: '/imoveis/aluguel/bela-vista/avenida-paulista' },
    {
        label: 'Rua Vinte e Cinco de Março',
        link: '/imoveis/aluguel/centro/rua-vinte-e-cinco-de-marco',
    },
    { label: 'Rua Teodoro Sampaio', link: '/imoveis/aluguel/pinheiros/rua-teodoro-sampaio' },
    { label: 'Avenida do Cursino', link: '/imoveis/aluguel/saude/avenida-do-cursino' },
    { label: 'Rua dos Pinheiros', link: '/imoveis/aluguel/pinheiros/rua-dos-pinheiros' },
    {
        label: 'Avenida Brigadeiro Faria Lima',
        link: '/imoveis/aluguel/jardim-paulistano/avenida-brigadeiro-faria-lima',
    },
    { label: 'Rua Augusta', link: '/imoveis/aluguel/cerqueira-cesar/rua-augusta' },
    { label: 'Rua Tuiuti', link: '/imoveis/aluguel/tatuape/rua-tuiuti' },
]
const price = [
    { label: 'Até R$1000', link: '/imoveis/aluguel/all?preço=0_1000' },
    { label: 'De R$1000 a R$2500', link: '/imoveis/aluguel?preço=1000_2500' },
    { label: 'De R$2500 a R$5000', link: '/imoveis/aluguel?preço=2500_5000' },
    { label: 'Acima de R$5000', link: '/imoveis/aluguel?preço=5000_100000' },
    { label: 'Abaixo de R$2500', link: '/imoveis/aluguel?preço=0_2500' },
    { label: 'Abaixo de R$5000', link: '/imoveis/aluguel?preço=0_5000' },
    { label: 'Abaixo de R$10.000', link: '/imoveis/aluguel?preço=0_10000' },
    { label: 'Barato', link: '/imoveis/aluguel/all?preço=0_1000' },
]
const size = [
    { label: 'Até 100 m²', link: '/imoveis/aluguel?tamanho-do-imovel=0_100' },
    { label: 'De 100 a 200 m²', link: '/imoveis/aluguel?tamanho-do-imovel=100_200' },
    { label: 'De 200 a 350 m²', link: '/imoveis/aluguel?tamanho-do-imovel=200_350' },
    { label: 'Acima de 350 m²', link: '/imoveis/aluguel?tamanho-do-imovel=350_100000' },
    { label: 'Pequeno', link: '/imoveis/aluguel?tamanho-do-imovel=0_100' },
    { label: 'Grande', link: '/imoveis/aluguel?tamanho-do-imovel=600_100000' },
]

export default function PreFooter() {
    const [tabSelected, setTabSelected] = useState(0)

    return (
        <>
            <div className="bg-gray-100">
                <div className="mx-auto max-w-screen-xl px-4 py-12">
                    <div className="flex-rol flex gap-6 md:gap-16">
                        {TABS.map((tab, index) => (
                            <button
                                key={index}
                                onClick={() => setTabSelected(index)}
                                className={`${
                                    tabSelected === index ? selectedTabClassName : ''
                                } line-h text-lg leading-10`}
                            >
                                {tab}
                            </button>
                        ))}
                    </div>

                    <div className="mt-[-0.125em] border-t-2 border-gray-300"></div>

                    <div className="mt-10 grid grid-cols-1 gap-4 md:grid-cols-3 lg:grid-cols-4">
                        {neighborhoods.map((el, index) => (
                            <div key={index} className={`${tabSelected === 0 ? '' : 'hidden'}`}>
                                <Link href={el.link}>
                                    <div>Imóveis comerciais para alugar em</div>
                                    <div className="font-semibold">{el.label}</div>
                                </Link>
                            </div>
                        ))}
                        {streets.map((el, index) => (
                            <div key={index} className={`${tabSelected === 1 ? '' : 'hidden'}`}>
                                <Link href={el.link}>
                                    <div>Imóveis comerciais para alugar em</div>
                                    <div className="font-semibold">{el.label}</div>
                                </Link>
                            </div>
                        ))}
                        {price.map((el, index) => (
                            <div key={index} className={`${tabSelected === 2 ? '' : 'hidden'}`}>
                                <Link href={el.link}>
                                    <div>Valor de aluguel comercial</div>
                                    <div className="font-semibold">{el.label}</div>
                                </Link>
                            </div>
                        ))}
                        {size.map((el, index) => (
                            <div key={index} className={`${tabSelected === 3 ? '' : 'hidden'}`}>
                                <Link href={el.link}>
                                    <div>Aluguel de imóvel comercial</div>
                                    <div className="font-semibold">{el.label}</div>
                                </Link>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    )
}
