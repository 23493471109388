import { AnimatePresence, FeatureBundle, LazyMotion, m } from 'framer-motion'
import { createContext, PropsWithChildren, useContext } from 'react'
import { twMerge } from 'tailwind-merge'

const animFeatures = (): Promise<FeatureBundle> =>
    import('utility/framerMotionFeatures').then((res: any) => res.default)

type Props<T extends string> = {
    id: T
    isOpen: boolean
    onToggle: (id: T) => void
}

const accordionVariants = {
    collapsed: { height: 0, opacity: 0 },
    open: { height: 'auto', opacity: 1 },
}

type AccordionContextType = {
    id: string
    isOpen: boolean
    onToggle: (id: string) => void
}

const AccordionContext = createContext<AccordionContextType>({
    id: '',
    isOpen: false,
    onToggle: () => {},
})

export default function Accordion<T extends string>({
    id,
    isOpen,
    onToggle,
    children,
}: PropsWithChildren<Props<T>>) {
    return (
        <AccordionContext.Provider
            value={{ id, isOpen, onToggle: onToggle as AccordionContextType['onToggle'] }}
        >
            <LazyMotion features={animFeatures} strict>
                <AnimatePresence initial={false}>
                    <m.div className="rounded-2xl border border-sq-gray-200 bg-white px-4 py-6 shadow-[0_4px_10px_0_#0000000D]">
                        {children}
                    </m.div>
                </AnimatePresence>
            </LazyMotion>
        </AccordionContext.Provider>
    )
}

Accordion.Header = function AccordionHeader({ id, children }: PropsWithChildren<{ id: string }>) {
    const { id: accordionId, onToggle } = useContext(AccordionContext)

    return (
        <m.div
            key={`${accordionId}-${id}`}
            className="flex cursor-pointer items-center justify-between"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => onToggle(accordionId)}
        >
            {children}
        </m.div>
    )
}

Accordion.HeaderOpen = function AccordionHeader({ children }: PropsWithChildren<{}>) {
    const { isOpen } = useContext(AccordionContext)

    if (!isOpen) {
        return null
    }

    return <Accordion.Header id="header-open">{children}</Accordion.Header>
}

Accordion.HeaderClosed = function AccordionHeader({ children }: PropsWithChildren<{}>) {
    const { isOpen } = useContext(AccordionContext)

    if (isOpen) {
        return null
    }

    return <Accordion.Header id="header-closed">{children}</Accordion.Header>
}

Accordion.Body = function AccordionBody({
    className,
    children,
}: PropsWithChildren<{ className?: string }>) {
    const { id, isOpen } = useContext(AccordionContext)

    if (!isOpen) {
        return null
    }

    return (
        <m.div
            key={`${id}-body`}
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={accordionVariants}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={twMerge('overflow-hidden pt-4', className)}
        >
            {children}
        </m.div>
    )
}
