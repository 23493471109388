import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<'input'> & {
    onCancel?: () => void
}

export default function SearchTextInput({ value, className, onCancel, ...props }: Props) {
    const hasText = typeof value === 'string' && value.length > 0

    return (
        <div className={twMerge('relative', className)}>
            {hasText && (
                <button
                    className="absolute right-6 h-full text-sm text-sq-gray-800 animate-in fade-in"
                    onClick={onCancel}
                >
                    Cancelar
                </button>
            )}
            <input
                type="text"
                value={value}
                className={twMerge(
                    'w-full rounded-full border border-sq-gray-200 bg-white px-8 py-4 text-sq-gray-800 placeholder-sq-gray-800 outline-none',
                    'focus:border-none focus:bg-sq-gray-50 focus:text-sq-gray-900 focus:placeholder-sq-gray-400'
                )}
                placeholder="Qualquer lugar em São Paulo, SP"
                autoComplete="address"
                {...props}
            />
        </div>
    )
}
