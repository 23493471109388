import Image from 'next/image'
import { PropsWithChildren } from 'react'
import HomeSearch from './HomeSearch'
import useAddresses from 'src/hooks/useAddresses'

const SearchContainer = ({ children }: PropsWithChildren) => (
    <div className="z-20 mb-4 mt-4 md:relative md:mb-10 md:min-h-[88px]">
        <div className="grid grid-cols-12 items-center gap-4 py-4 md:absolute md:top-4 md:mr-2 md:w-[155%] md:min-w-[694px]">
            {children}
        </div>
    </div>
)

function HomeHeader() {
    const { regions, logradouros } = useAddresses()

    return (
        <section className="container grid size-full h-full max-w-screen-xl grid-cols-12 md:mx-auto md:mb-16">
            <div className="col-span-12 mt-4 px-4 md:col-span-5 md:mt-[96px] lg:my-auto">
                <h1 className="font mb-4 mt-5 text-3xl md:my-0 md:text-[2.5rem]">
                    Alugue o{' '}
                    <b className="font-bold">
                        imóvel <br />
                        comercial ideal
                    </b>
                    <br /> para o seu negócio
                </h1>
                <SearchContainer>
                    <HomeSearch regions={regions} logradouros={logradouros} />
                </SearchContainer>
            </div>
            <div className="z-1 relative col-span-7 hidden aspect-[1.62] md:block xl:mr-[-40px]">
                <Image
                    src={require('public/man-using-digital-tablet.webp')}
                    alt="Man using digital tablet"
                    width={818}
                    height={504}
                    className="border-bl-6xl col-span-7 ml-auto w-full object-cover xl:rounded-br-6xl"
                    priority
                />
                <div className="absolute left-0 top-0 h-[50%] w-full bg-gradient-to-b from-black to-transparent opacity-70" />
            </div>
        </section>
    )
}

export default HomeHeader
