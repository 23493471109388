import Link from 'next/link'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = ComponentProps<typeof Link>

function SQLinkButton({ children, ...props }: Props) {
    return (
        <Link
            {...props}
            className={twMerge(
                'flex w-full flex-row items-center justify-center gap-2 rounded-full bg-primary px-4 py-3 text-center font-semibold text-white',
                props.className
            )}
        >
            {children}
        </Link>
    )
}

export default SQLinkButton
