import SQLinkButton from '@/_shared/SQLinkButton/SQLinkButton'
import SQSpinner from '@/_shared/SQSpinner/SQSpinner'
import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import { ComponentProps } from 'react'
import { twMerge } from 'tailwind-merge'

type Props = {
    isLoading?: boolean
    isAnimated?: boolean
    onClick: () => void
} & Omit<ComponentProps<typeof SQLinkButton>, 'href' | 'tabIndex' | 'onClick'>

export default function SubmitButton({
    isLoading,
    isAnimated,
    className,
    onClick,
    ...props
}: Props) {
    return (
        <SQLinkButton
            href="#"
            className={twMerge(
                'group flex w-full flex-row items-center justify-center text-sm font-semibold outline-secondary transition-all',
                className
            )}
            onClick={(e) => {
                e.preventDefault()
                onClick()
            }}
            {...props}
        >
            Buscar
            {isLoading ? (
                <SQSpinner svgClasses="size-5" />
            ) : (
                <MagnifyingGlassIcon
                    className={twMerge(
                        'size-5 fill-secondary transition-transform',
                        isAnimated && 'group-hover:scale-125 group-focus:scale-125'
                    )}
                />
            )}
        </SQLinkButton>
    )
}
