import styles from './BestPlatform.module.scss'

export default function BestPlatform() {
    return (
        <div className={styles.Container}>
            <div className={styles.containerTitle}>
                <h2>Aluguel comercial em São Paulo de um jeito descomplicado</h2>
            </div>
            <div className={styles.topicsContainer}>
                <div className={styles.topic}>
                    <h2 className={styles.title}>Pontos comerciais em um só lugar</h2>
                    <p className={styles.content}>
                        Reunimos os melhores imóveis comerciais da cidade em um só lugar para você
                        economizar seu tempo.
                    </p>
                </div>
                <div className={styles.topic}>
                    <h2 className={styles.title}>Imóveis únicos</h2>
                    <p className={styles.content}>
                        Uma busca mais eficiente e rápida, sem imóveis duplicados ou indisponíveis.
                    </p>
                </div>
                <div className={styles.topic}>
                    <h2 className={styles.title}>Informações complementares</h2>
                    <p className={styles.content}>
                        Dados sobre a região, fluxo de pedestres e zoneamento. Tudo o que você
                        precisa para acertar na escolha.
                    </p>
                </div>
                <div className={styles.topic}>
                    <h2 className={styles.title}>Aluguel simples</h2>
                    <p className={styles.content}>
                        Agendamento de visitas em poucos cliques, recomendações de imóveis e suporte
                        até a assinatura do contrato.
                    </p>
                </div>
            </div>
        </div>
    )
}
