import useSearchAutocomplete from '@/search/useSearchAutocomplete'
import { useCallback, useEffect, useMemo, useReducer, useRef, useState } from 'react'
import {
    BairroLogradouroType,
    RegionType,
    SearchValue,
    SupplySearchParams,
} from 'src/@core/search/search.model'
import SearchAutocomplete from './SearchAutocomplete'
import { twMerge } from 'tailwind-merge'
import PriceFilter from './PriceFilter'
import Popup from './Popup'
import SizeFilter from './SizeFilter'
import { useRouter } from 'next/router'
import { EventType, getBaseTracksValues, track } from '@/tracks'
import {
    createLogradouroSearch,
    createQuerySearch,
    createRegionSearch,
    toSearchText,
    priceToText,
    sizeToText,
    toSearchUrl,
} from 'src/@core/search/search.utils'
import SearchParamsModal from './SearchParamsModal'
import SubmitButton from './SubmitButton'
import {
    defaultPrice,
    defaultSize,
    initialSearchParams,
    maxPrice,
    maxSize,
    setMaxPrice,
    setMaxSize,
    setMinPrice,
    setMinSize,
    setQuery,
    supplySearchReducer,
} from 'src/@core/search/search.state'

type PopupName = 'address' | 'price' | 'size'

const buildHomeSearchParams = (params: SupplySearchParams) =>
    [
        EventType.HomeSearch,
        {
            ...getBaseTracksValues(),
            searchQueryType: params.query.type,
            searchQueryText: toSearchText(params.query),
            searchPriceMin: params.price?.min,
            searchPriceMax: params.price?.type === 'closed' ? params.price.max : undefined,
            searchSizeMin: params.size?.min,
            searchSizeMax: params.size?.type === 'closed' ? params.size.max : undefined,
        },
    ] as const

const desktopOnly = 'hidden md:flex'
const mobileOnly = 'flex md:hidden'

type Props = {
    regions: RegionType[]
    logradouros: BairroLogradouroType[]
}

export default function HomeSearch({ regions, logradouros }: Props) {
    const queryInputRef = useRef<HTMLInputElement>(null)
    const [params, dispatch] = useReducer(
        supplySearchReducer({
            defaultPrice,
            defaultSize,
            maxPrice,
            maxSize,
        }),
        initialSearchParams
    )
    const [focusedPopup, setFocusedPopup] = useState<PopupName | null>(null)
    const [isOpenModal, setIsOpenModal] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const queryText = useMemo(() => toSearchText(params.query), [params.query])
    const priceText = useMemo(
        () => (params.price ? priceToText(params.price) : undefined),
        [params.price]
    )
    const sizeText = useMemo(
        () => (params.size ? sizeToText(params.size) : undefined),
        [params.size]
    )
    const isSearchPopupVisible = focusedPopup === 'address'
    const isPricePopupVisible = focusedPopup === 'price'
    const isSizePopupVisible = focusedPopup === 'size'

    const onChangeQuery = (newQuery: SearchValue) => dispatch(setQuery(newQuery))

    const onChangeMinPrice = (newMin: number) => dispatch(setMinPrice(newMin))
    const onChangeMaxPrice = (newMax: number) => dispatch(setMaxPrice(newMax))

    const onChangeMinSize = (newMin: number) => dispatch(setMinSize(newMin))
    const onChangeMaxSize = (newMax: number) => dispatch(setMaxSize(newMax))

    const { filteredRegions, filteredLogradouros } = useSearchAutocomplete({
        query: queryText,
        regions,
        logradouros,
    })

    const onSelectRegion = (result: RegionType) => {
        onChangeQuery(createRegionSearch(result))
        setTimeout(() => setFocusedPopup('price'), 300)
    }

    const onSelectLogradouro = (result: BairroLogradouroType) => {
        onChangeQuery(createLogradouroSearch(result))
        setTimeout(() => setFocusedPopup('price'), 300)
    }

    const onClosePopup = (currentPopup: PopupName) =>
        setTimeout(() => {
            // Check if the query input is still focused
            if (currentPopup === 'address' && queryInputRef.current === document.activeElement) {
                return
            }

            setFocusedPopup((prev) => (prev === currentPopup ? null : prev))
        }, 100)

    useEffect(() => {
        if (focusedPopup !== 'address' && params.query.type === 'query') {
            onChangeQuery(createQuerySearch(''))
        }
    }, [focusedPopup, params.query.type])

    const router = useRouter()

    const onSubmit = useCallback(async () => {
        setIsSubmitting(true)

        await Promise.all([
            track(...buildHomeSearchParams(params)),
            router.push(toSearchUrl(params)),
        ])
    }, [router, params])

    const onPressSearchModal = () => setIsOpenModal(true)

    return (
        <div
            className={twMerge(
                'col-span-12 rounded-4xl border border-sq-gray-200 bg-white shadow-[0_4px_16px_0_#0000000F] transition-all',
                'flex flex-col items-stretch justify-between gap-1 p-3 px-4',
                'md:flex-row md:rounded-full',
                focusedPopup && 'md:shadow-none'
            )}
        >
            <label
                className={twMerge(
                    desktopOnly,
                    'relative flex-1 flex-col px-8 py-3 hover:cursor-pointer',
                    focusedPopup === 'address' && 'rounded-full border-none bg-sq-gray-100'
                )}
            >
                <span className="text-base">Onde?</span>
                <input
                    ref={queryInputRef}
                    tabIndex={0}
                    placeholder="Defina o local"
                    className="border-none bg-transparent text-xs placeholder:text-sq-gray-500 focus:outline-none"
                    value={queryText}
                    autoComplete="off"
                    onChange={(e) => onChangeQuery(createQuerySearch(e.target.value))}
                    onFocus={() => setTimeout(() => setFocusedPopup('address'), 100)}
                />
                <Popup
                    className="px-8 py-2"
                    isVisible={isSearchPopupVisible}
                    onClose={() => onClosePopup('address')}
                >
                    <SearchAutocomplete
                        query={queryText}
                        filteredRegions={filteredRegions}
                        filteredLogradouros={filteredLogradouros}
                        fallBackRegions={regions.slice(0, 3)}
                        fallBackLogradouros={logradouros.slice(0, 3)}
                        onSelectRegion={onSelectRegion}
                        onSelectLogradouro={onSelectLogradouro}
                    />
                </Popup>
            </label>
            <button
                tabIndex={0}
                className={twMerge(
                    desktopOnly,
                    'relative flex-col border-sq-gray-100 px-4 py-3 outline-none transition-all xl:px-8',
                    !focusedPopup && 'border-x',
                    focusedPopup === 'size' && 'border-l',
                    focusedPopup === 'price' && 'rounded-full border-none bg-sq-gray-100'
                )}
                onClick={() => setFocusedPopup('price')}
                onFocus={() => setFocusedPopup('price')}
            >
                <span className="text-base">Qual faixa de preço?</span>
                <span className="text-xs tabular-nums text-sq-gray-500">
                    {priceText ?? 'Defina o valor'}
                </span>
                <Popup
                    className="px-8 py-6"
                    isVisible={isPricePopupVisible}
                    onClose={() => onClosePopup('price')}
                >
                    <PriceFilter
                        value={params.price ?? defaultPrice}
                        onChangeMin={onChangeMinPrice}
                        onChangeMax={onChangeMaxPrice}
                    />
                </Popup>
            </button>
            <button
                tabIndex={0}
                className={twMerge(
                    desktopOnly,
                    'relative flex-col border-sq-gray-100 px-4 py-3 text-left outline-none transition-all focus:rounded-full focus:border-none focus:bg-sq-gray-100 xl:px-8',
                    focusedPopup === 'address' && 'border-l',
                    focusedPopup === 'size' && 'rounded-full border-none bg-sq-gray-100'
                )}
                onClick={() => setFocusedPopup('size')}
                onFocus={() => setFocusedPopup('size')}
            >
                <span className="text-base">Qual o tamanho?</span>
                <span className="text-xs text-sq-gray-500">{sizeText ?? 'Defina a metragem'}</span>
                <Popup
                    className="px-8 py-6"
                    isVisible={isSizePopupVisible}
                    onClose={() => onClosePopup('size')}
                >
                    <SizeFilter
                        value={params.size ?? defaultSize}
                        onChangeMin={onChangeMinSize}
                        onChangeMax={onChangeMaxSize}
                    />
                </Popup>
            </button>
            <SubmitButton
                isAnimated
                isLoading={isSubmitting}
                onClick={onSubmit}
                className={twMerge(desktopOnly, 'my-1 ml-1 max-w-[120px]')}
            />
            <button
                className={twMerge(mobileOnly, 'mt-2 w-full flex-col')}
                onClick={onPressSearchModal}
            >
                <b className="font-semibold">{queryText || 'Qualquer lugar em São Paulo, SP'}</b>
                <div className="flex flex-row items-center gap-1 text-sq-gray-600">
                    <span>{priceText ?? 'Qualquer preço'}</span>
                    <span className="text-xl">&middot;</span>
                    <span>{sizeText ?? 'Qualquer tamanho'}</span>
                </div>
            </button>
            <SubmitButton
                onClick={() => setIsOpenModal(true)}
                className={twMerge(mobileOnly, 'my-2')}
            />
            <SearchParamsModal
                isVisible={isOpenModal}
                isSubmitting={isSubmitting}
                params={params}
                regions={regions}
                logradouros={logradouros}
                defaultPrice={defaultPrice}
                defaultSize={defaultSize}
                onChange={dispatch}
                onClose={() => setIsOpenModal(false)}
                onSubmit={onSubmit}
            />
        </div>
    )
}
